<template>
  <div class="page">
    <div class="notice" v-if="showNotice">
      <div class="conWrap">
        <div class="title">温馨提示：</div>
        <div class="con" v-html="notice.nContent" v-if="notice.nContent"></div>
        <div class="con" v-else style="text-align: center">暂未发布</div>
      </div>
      <div class="btnWrap">
        <a-button
          type="primary"
          shape="round"
          size="large"
          block
          class="btn"
          @click="know()"
        >
          我已阅读并知晓，开始填报
        </a-button>
      </div>
    </div>
    <div class="form" v-else>
      <a-form id="mbxxForm" :form="form" ref="mbxxForm">
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>学生信息</div>
          </div>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="学生姓名">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['xm']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12" class="self_radio">
              <a-form-item label="性别">
                <a-radio-group
                  disabled
                  v-decorator="[
                    'xb',
                    {
                      rules: [{ required: true, message: '请选择性别' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-radio value="1"> 男 </a-radio>
                  <a-radio value="2"> 女 </a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="证件号码">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['zjh']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="监护人姓名">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['jhr']"
                ></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="监护人手机号">
                <a-input
                  class="cus-input"
                  size="large"
                  type="text"
                  disabled
                  v-decorator="['jhrdh']"
                ></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="12"> </a-col>
          </a-row>
        </div>
        <div class="section">
          <div class="part">
            <div class="title"><span class="line"></span>选报学校</div>
          </div>
          <!-- 第一意向 -->
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="第一意向学校">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="schoolChange"
                  v-decorator="[
                    'yxSchoolId',
                    {
                      rules: [{ required: true, message: '请选择' }],
                      validateTrigger: 'change',
                    },
                  ]"
                >
                  <a-select-option
                    v-for="item in schoolList1"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                label="第一意向关联派位"
                v-if="glAbandon || glConfirm"
              >
                <div v-if="glAbandon" class="tip">已放弃关联派位<br /></div>
                <div v-if="glConfirm">
                  <div class="glyh-list" :style="{ background: '#fff' }">
                    <div v-for="item in glyhList" :key="item.id">
                      <span>{{ item.stuXm }}：</span
                      ><span>{{ item.stuZjh }}</span>
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>

            <!-- 关联排位start -->
            <Glyh
              ref="glyh1"
              serial="一"
              @getGlyhIds="glyhRegIds"
              @abandon="setGlAbandon"
            />
            <!-- 关联排位end -->
          </a-row>

          <!-- 第二意向 -->
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="第二意向学校">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="schoolSecondChange"
                  v-decorator="['yxSchoolSecondId']"
                >
                  <a-select-option
                    v-for="item in schoolList2"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                label="第二意向关联派位"
                v-if="glAbandonSecond || glConfirmSecond"
              >
                <div v-if="glAbandonSecond" class="tip">
                  已放弃关联派位<br />
                </div>
                <div v-if="glConfirmSecond">
                  <div class="glyh-list" :style="{ background: '#fff' }">
                    <div v-for="item in glyhSecondList" :key="item.id">
                      <span>{{ item.stuXm }}：</span
                      ><span>{{ item.stuZjh }}</span>
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>
            <Glyh
              ref="glyh2"
              serial="二"
              @getGlyhIds="glyhRegIdsSecond"
              @abandon="setGlAbandonSecond"
            />
          </a-row>

          <!-- 第三意向 -->
          <a-row :gutter="80">
            <a-col :span="12">
              <a-form-item label="第三意向学校">
                <a-select
                  size="large"
                  placeholder="请选择"
                  @change="schoolThirdChange"
                  v-decorator="['yxSchoolThirdlyId']"
                >
                  <a-select-option
                    v-for="item in schoolList3"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :span="12">
              <a-form-item
                label="第三意向关联派位"
                v-if="glAbandonThird || glConfirmThird"
              >
                <div v-if="glAbandonThird" class="tip">
                  已放弃关联派位<br />
                </div>
                <div v-if="glConfirmThird">
                  <div class="glyh-list" :style="{ background: '#fff' }">
                    <div v-for="item in glyhThirdList" :key="item.id">
                      <span>{{ item.stuXm }}：</span
                      ><span>{{ item.stuZjh }}</span>
                    </div>
                  </div>
                </div>
              </a-form-item>
            </a-col>
            <Glyh
              ref="glyh3"
              serial="三"
              @getGlyhIds="glyhRegIdsThird"
              @abandon="setGlAbandonThird"
            />
          </a-row>

          <a-row
            v-if="
              glConfirm ||
              glConfirmSecond ||
              glConfirmThird ||
              glAbandon ||
              glAbandonSecond ||
              glAbandonThird
            "
          >
            <div class="tip">
              重要提示：如您进行关联派位，多人将视为一人参加派位，成功则多人均被录取，未成功则多人均未被录取；如您放弃关联派位，多人将分别独立参加派位。
            </div>
          </a-row>
        </div>
        <div class="saveBtn">
          <a-row :gutter="24">
            <a-col :span="7" :offset="5">
              <a-button
                type="primary"
                shape="round"
                size="large"
                block
                class="pre-button btn"
                @click="save(2)"
              >
                保存
              </a-button>
            </a-col>
            <a-col :span="7">
              <a-popconfirm
                placement="top"
                ok-text="确认"
                cancel-text="取消"
                @confirm="save(3)"
              >
                <template slot="title">
                  <p>温馨提示</p>
                  <p>确认提交填报信息并下载登记表吗？</p>
                </template>
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="btn"
                >
                  提交，并下载登记表
                </a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import pick from "lodash.pick";
import { getMbNotice } from "@/api/notice";
import { getSchoolList, getMbDetail, getIfGlyh, saveMbxb } from "@/api/form";
import { getFlag } from "@/api/common";
import Glyh from "./glyh/glyh.vue";

export default {
  components: { Glyh },
  data() {
    return {
      showNotice: true,
      disabled: false,
      notice: {},
      form: this.$form.createForm(this),
      glyhCur: "", // 关联派位  是否
      glyhFlag: false,
      schoolList: [], // 学区下的学校
      schoolList1: [], // 学区下的学校 第一意向
      schoolList2: [], // 学区下的学校 第二意向
      schoolList3: [], // 学区下的学校 第三意向
      detailInfo: {},
      childrens: [],
      glRegIds: "",
      glSecondRegIds: "",
      glThirdRegIds: "",
      glyhModal: false, //
      glyhList: [
        { id: 1, name: "张三", idCade: "140106" },
        { id: 1, name: "张三", idCade: "140106" },
      ], // 关联派位
      glyhSecondList: [],
      glyhThirdList: [],
      glAbandon: false, // 放弃关联
      glAbandonSecond: false, // 放弃关联
      glAbandonThird: false, // 放弃关联
      glConfirm: false, // 确认关联
      glConfirmSecond: false, // 确认关联
      glConfirmThird: false, // 确认关联
    };
  },
  filters: {},
  methods: {
    // 多胞胎关联选择
    itemRadio(item) {
      console.log(888, item.stuGlIds);
      if (this.glRegIds == item.stuGlIds) {
        this.glRegIds = "";
      } else {
        this.glRegIds = item.stuGlIds;
      }
      console.log(999, this.glRegIds);
    },
    // 须知确认已阅
    know() {
      getFlag({
        paramKey: "mbxxSelect",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          this.showNotice = false;
          this.getSchoolList();
        } else {
          this.$message.info("填报暂未开启");
        }
      });
    },
    // 温馨提示
    getNotice() {
      getMbNotice({
        fjType: this.type,
      }).then((res) => {
        console.log(res, "2233");
        this.notice = res.data;
      });
    },
    // 获取民办学校
    getSchoolList() {
      getSchoolList().then((res) => {
        this.schoolList = res.data;
        this.schoolList1 = res.data;
        this.$nextTick(() => {
          this.getDetailInfo();
        });
      });
    },
    glyhRegIds(values, list) {
      this.glRegIds = values;
      this.glyhList = list;
      this.glConfirm = true;
    },
    glyhRegIdsSecond(values, list) {
      this.glSecondRegIds = values;
      this.glyhSecondList = list;
      this.glConfirmSecond = true;
    },
    glyhRegIdsThird(values, list) {
      this.glThirdRegIds = values;
      this.glyhThirdList = list;
      this.glConfirmThird = true;
    },
    setGlAbandon() {
      this.glRegIds = "";
      this.glyhList = []
      this.glAbandon = true;
    },
    setGlAbandonSecond() {
      this.glSecondRegIds = "";
      this.glyhSecondList = []
      this.glAbandonSecond = true;
    },
    setGlAbandonThird() {
      this.glThirdRegIds = "";
      this.glyhSecondList = []
      this.glAbandonThird = true;
    },
    schoolChange(e) {
      this.form.resetFields(['yxSchoolSecondId','yxSchoolThirdlyId'])
      // yxSchoolId 参数
      console.log(e);
      this.glAbandon = false;
      this.glAbandonSecond = false;
      this.glAbandonThird = false;
      this.glConfirm = false;
      this.glConfirmSecond = false;
      this.glConfirmThird = false;
      this.glRegIds = "";
      this.glSecondRegIds = "";
      this.glThirdRegIds = "";

      this.schoolList2 = this.schoolList1.filter((item) => {
        if (e !== item.id) return item;
      });

      this.getIfGlyh(e, 1);
    },
    schoolSecondChange(e) {
      this.form.resetFields(['yxSchoolThirdlyId'])
      this.glAbandonSecond = false;
      this.glAbandonThird = false;
      this.glConfirmSecond = false;
      this.glConfirmThird = false;
      this.glSecondRegIds = "";
      this.glThirdRegIds = "";

      this.schoolList3 = this.schoolList2.filter((item) => {
        if (e !== item.id) return item;
      });
      this.getIfGlyh(e, 2);
    },
    schoolThirdChange(e) {
      this.glAbandonThird = false;
      this.glConfirmThird = false;
      this.glThirdRegIds = "";

      this.getIfGlyh(e, 3);
    },
    getDetailInfo() {
      getMbDetail().then((res) => {
        this.detailInfo = res.data;
        this.glRegIds = this.detailInfo.glRegIds;
        if (this.detailInfo.yxSchoolId) {
          this.schoolList2 = this.schoolList1.filter((item) => {
            if (this.detailInfo.yxSchoolId !== item.id) return item;
          });
        }

        if (this.detailInfo.yxSchoolSecondId) {
          this.schoolList3 = this.schoolList2.filter((item) => {
            if (this.detailInfo.yxSchoolSecondId !== item.id) return item;
          });
        }
        if (this.detailInfo.sfglyh == 1) {
          this.glyhList = this.detailInfo.glRegInfos;
          this.glConfirm = true;
        } else if (this.detailInfo.sfglyh == 2){
          this.glAbandon = true;
        }
        if (this.detailInfo.sfglyhSecond == 1) {
          this.glyhList = this.detailInfo.glRegInfos;
          this.glConfirm = true;
        } else if (this.detailInfo.sfglyhSecond == 2){
          this.glAbandonSecond = true;
        }
        if (this.detailInfo.sfglyhThirdly == 1) {
          this.glyhList = this.detailInfo.glRegInfos;
          this.glConfirmThird = true;
        } else if (this.detailInfo.sfglyhThirdly == 2) {
          this.glAbandonThird = true;
        }
        this.setFormData();
      });
    },
    // 是否显示关联派位
    getIfGlyh(yxSchoolId, type) {
      getIfGlyh({
        yxSchoolId: yxSchoolId,
        rank: type,
      }).then((res) => {
        if (res.data.length > 0) {
          this.$refs[`glyh${type}`].show(res.data);
        } else {
          this.glyhModal = false;
        }
      });
    },
    save(type) {
      this.form.validateFields((err, values) => {
        // 默认 1 保存 2  提交 3
        values.xbxxStatus = type;
        values.id = this.detailInfo.id;
        values.glRegIds = this.glRegIds;
        values.glSecondRegIds = this.glSecondRegIds;
        values.glThirdlyRegIds = this.glThirdRegIds;

        this.schoolList1.forEach((ele, idx) => {
          if (ele.id == values.yxSchoolId) {
            values.yxSchoolName = ele.name;
          }
        });
        this.schoolList2.forEach((ele, idx) => {
          if (ele.id == values.yxSchoolSecondId) {
            values.yxSchoolSecondName = ele.name;
          }
        });
        this.schoolList3.forEach((ele, idx) => {
          if (ele.id == values.yxSchoolThirdlyId) {
            values.yxSchoolThirdlyName = ele.name;
          }
        });
        values.schoolCode = localStorage.getItem("schoolCode");
        values.djType = localStorage.getItem("stage");
        values.djTypeValue = localStorage.getItem("stageText");
        if (!err) {
          console.log(values);
          // return
          saveMbxb(values).then((res) => {
            if (res.code == 0) {
              this.$message.success(res.description);
              if (type == 3) {
                window.open(
                  `/fast-imagine/mobile/yw/exportDjb?fjType=2&userCode=${localStorage.getItem(
                    "userCode"
                  )}`
                );
                this.$emit("reload");
              }
            } else {
              this.$message.error(res.description);
            }
          });
        }
      });
    },
    setFormData() {
      const formData = pick(this.detailInfo, [
        "id",
        "jhr",
        "jhrdh",
        "xb",
        "xm",
        "yxSchoolId",
        "yxSchoolSecondId",
        "yxSchoolThirdlyId",
        "yxSchoolName",
        "yxSchoolTypeText",
        "yxSchoolType",
        "zjh",
      ]);
      this.$nextTick(() => {
        this.form.setFieldsValue(formData);
      });
    },
    // // 关联派位 -- 取消
    // glyhCancel() {
    //   this.glyhModal = false;
    //   this.glAbandon = true;
    // },
    // // 关联派位 -- 确定
    // glyhConfirm() {
    //   this.glyhModal = false;
    //   this.glConfirm = true;

    //   let arr = [];
    //   this.glyhList.forEach((item) => {
    //     arr.push(item.regId);
    //   });

    //   this.glRegIds = arr.toString();
    // },
  },
  mounted() {
    this.getNotice();
    // this.$nextTick(() => {
    // });
  },
};
</script>

<style lang="less" scoped>
.tip {
  color: #ff7575;
  font-size: 14px;
}
.notice {
  .conWrap {
    padding: 52px 77px 32px;
    .title {
      color: #333;
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 24px;
    }
  }
  .btnWrap {
    text-align: center;
  }
  .btn {
    margin: 24px auto 16px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
.section {
  .part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f7f7f7;
    padding: 8px 16px;
    margin-bottom: 12px;
    margin-top: 28px;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .line {
    display: inline-block;
    width: 3px;
    height: 16px;
    background: #35ab61;
    border-radius: 2px;
    margin-right: 6px;
  }
  .scan {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
  }
  .del {
    font-size: 14px;
    color: #ff5454;
  }
  .icon {
    margin-right: 6px;
  }
  .lookDemo {
    font-size: 14px;
    color: #35ab61;
    cursor: pointer;
    position: absolute;
    right: 40px;
    top: 8px;
  }
}
.self_radio {
  .ant-radio-wrapper {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    height: 40px;
    line-height: 27px;
    padding: 6px 11px;
    width: 181px;
    &:last-child {
      margin-right: 0;
    }
  }
}
/deep/ .ant-form-item-control {
  line-height: inherit;
}
/deep/ .ant-form-item {
  margin-bottom: 12px;
}
/deep/ .ant-input {
  font-size: 14px;
}
.saveBtn {
  margin-top: 28px;
  margin-bottom: 16px;
  .btn {
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
  .pre-button {
    border-color: #35ab61;
    background: #fff;
    color: #35ab61;
    box-shadow: none;
  }
}
.row {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 6px 0;
  .icon {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .name {
    color: #000;
    padding-left: 2px;
  }
  .card {
    color: #666;
    padding-left: 8px;
  }
  .status {
    color: #999;
    padding-left: 20px;
  }
  .done {
    color: #35ab61;
  }
}
.glyh-mask {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.glyh-content {
  width: 50%;
  margin: 100px auto 0;
  padding: 30px 60px;
  background: #fff;
}
.glyh-list {
  background: #e9e9e9;

  div {
    padding: 10px 0px;
  }
}
.glyh-footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
</style>
