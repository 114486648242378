<template>
  <div class="page">
    <home-header
      activeIndex="0"
      :pageTitle="curStep == 'xxxbInfo' ? '学校选报' : '报名流程'"
    />
    <div class="wrap">
      
      <div class="application" v-if="curStep == 'xxxbInfo'">
        报名条件：<span style="color: red">xxxxxxxxxxxxxxx</span>
      </div>
      <div class="steps" v-else>
        <div
          class="steps-item steps-first steps-item-finish"
          :class="curStep == 'baseInfo' ? 'steps-item-active' : ''"
        >
          <div class="steps-item-title" @click="toChange('baseInfo')">
            基本信息查看
          </div>
        </div>
        
        <div
          class="steps-item"
          v-if="!hyResult"
          :class="
            curStep != 'regResult'
              ? 'steps-item-finish'
              : curStep == 'regResult' || !xxdjStatus
              ? 'steps-item-active'
              : ''
          "
        >
          <div class="steps-item-title" @click="toChange('regResult')">
            查看核验结果
          </div>
        </div>

        <!-- 信息登记 -->
        <div
          class="steps-item"
          v-if="xxdjStatus"
          :class="
            (xxdjStatus == 2 && curStep != 'xxdjInfo') ||
            (xxdjStatus == 1 && curStep != 'xxdj')
              ? 'steps-item-finish'
              : (xxdjStatus == 1 && curStep == 'xxdj') ||
                (curStep == 'xxdjInfo' && xxdjStatus == 2)
              ? 'steps-item-active'
              : ''
          "
        >
          <!-- 详情 -->
          <div
            class="steps-item-title"
            v-if="xxdjStatus == 2"
            @click="toChange('xxdjInfo')"
          >
            信息登记查看
          </div>
          <!-- 填报 -->
          <div class="steps-item-title" v-else @click="toChange('xxdj')">
            信息登记
          </div>
        </div>
        <div
          class="steps-item"
          v-else
          :class="
            hyResult && curStep != 'xxdj'
              ? 'steps-item-finish'
              : curStep == 'xxdj'
              ? 'steps-item-active'
              : ''
          "
        >
          <!-- 填报 -->
          <div
            class="steps-item-title"
            @click="toChange('xxdj')"
            v-if="hyResult"
          >
            信息登记
          </div>
          <div class="steps-item-title" v-else>信息登记</div>
        </div>

        <!-- 打印登记表 v-if="xxdjStatus == 2"-->
        <div
          class="steps-item"
          v-if="xxdjStatus == 2"
          :class="
            (dydjbStatus == 1 || dydjbStatus == 2) && curStep == 'download'
              ? 'steps-item-active'
              : (dydjbStatus == 1 || dydjbStatus == 2) && curStep != 'download'
              ? 'steps-item-finish'
              : ''
          "
        >
          <div
            class="steps-item-title"
            v-if="dydjbStatus == 1"
            @click="toChange('download')"
          >
            打印登记表查看
          </div>
          <div class="steps-item-title" v-else @click="toChange('download')">
            打印登记表
          </div>
        </div>
        <div class="steps-item" v-else>
          <div class="steps-item-title">打印登记表</div>
        </div>

        <!-- 民办学校选报start -->
        <div
          class="steps-item"
          v-if="xbxxStatus == 1 && !lqxxStatus "
          :class="
            dydjbStatus == 1 && curStep != 'xb'
              ? 'steps-item-finish'
              : dydjbStatus == 1 && curStep == 'xb'
              ? 'steps-item-active'
              : ''
          "
        >
          <div
            class="steps-item-title"
            @click="toChange('xb')"
            v-if="dydjbStatus == 1"
          >
            民办学校选报
          </div>
          <div class="steps-item-title" v-else>民办学校选报</div>
        </div>
        <div
          class="steps-item"
          v-if="(xbxxStatus == 2 || xbxxStatus == 3) && yxSchoolType != '1'"
          :class="
            (xbxxStatus == 3 && curStep != 'xbInfo') ||
            (xbxxStatus == 2 && curStep != 'xb')
              ? 'steps-item-finish'
              : (xbxxStatus == 2 && curStep == 'xb') ||
                (xbxxStatus == 3 && curStep == 'xbInfo')
              ? 'steps-item-active'
              : ''
          "
        >
          <div
            class="steps-item-title"
            v-if="xbxxStatus == 3"
            @click="toChange('xbInfo')"
          >
            民办学校选报查看
          </div>
          <div
            class="steps-item-title"
            v-if="xbxxStatus == 2"
            @click="toChange('xb')"
          >
            民办学校选报
          </div>
        </div>
        <div
          class="steps-item steps-item-finish"
          v-if="xbxxStatus == 1 && lqxxStatus"
          :class="curStep == 'xb' ? 'steps-item-active' : ''"
        >
          <div class="steps-item-title" @click="toChange('xb')">
            民办学校选报（未报）
          </div>
        </div>
        <!-- 民办学校选报end -->

        <!-- 入学结果 -->
        <!-- xbxxStatus == 3 ||
                (dydjbStatus == 1 && !xbxxStatus) || -->
        <div
          class="steps-item steps-last"
          :class="
            
            (xbxxStatus == 1 && lqxxStatus && curStep != 'lqResult')
              ? 'steps-item-finish'
              : 
                (xbxxStatus == 1 && lqxxStatus) ||
                (!xbxxStatus && lqxxStatus && curStep == 'lqResult')
              ? 'steps-item-active'
              : ''
          "
        >
          <div
            class="steps-item-title"
            v-if="
              xbxxStatus == 3 ||
              (xbxxStatus == 1 && lqxxStatus) ||
              (!xbxxStatus && lqxxStatus)
            "
            @click="toChange('lqResult')"
          >
            入学结果
          </div>
          <div class="steps-item-title" v-else>入学结果</div>
        </div>
      </div>

      <!-- 基本信息 -->
      <div class="steps-item-content" v-if="curStep == 'baseInfo'">
        <!-- 基本信息详情 -->
        <base-info ref="baseDetail"></base-info>
      </div>

      <!-- 核验结果 v-if="!xxdjStatus"-->
      <div class="steps-item-content" v-if="curStep == 'regResult' && !xxdjStatus">
        <div class="result" v-if="xxdjStatus != 0">
          <img class="img" :src="require('@/assets/home/right.png')" alt="" />
          <div class="title">核验结果</div>
          <div class="desc">
            恭喜！您已通过网上报名审核。 <br />
            报名流程尚未结束，请继续完善入学信息，如未完善，信息登记无效，不予安排学位！
          </div>
          <a-button
            type="primary"
            shape="round"
            size="large"
            block
            class="btn"
            @click="toNext"
          >
            下一步 <a-icon type="arrow-right" />
          </a-button>
        </div>
      </div>

      <!-- 信息登记 -->
      <div v-if="curStep == 'xxdjInfo' || curStep == 'xxdj'">
        <div class="steps-item-content" v-if="xxdjStatus == 2">
          <!-- 信息登记详情 -->
          <xxdj-info ref="xxdjDetail"></xxdj-info>
        </div>
        <div class="steps-item-content" v-else>
          <!-- 信息登记填报 -->
          <xxdj-form ref="xxdjForm" @reload="getStatus"></xxdj-form>
        </div>
      </div>

      <!-- 打印登记表 -->
      <div class="steps-item-content" v-if="curStep == 'download'">
        <Table ref="tableInfo" @reload="getStatus" />
      </div>

      <!-- 民办学校选报 -->
      <!-- 未选报民办学校--已分配  跳过该步骤 -->
      <div v-if="(curStep == 'xbInfo' || curStep == 'xb') && xbxxStatus == 1 && lqxxStatus">
        <div class="steps-item-content">
          <div style="text-align: center; padding-top: 120px">
            您当前已分配学校，无法选报民办学校
          </div>
        </div>
      </div>
      <!-- v-if="(curStep == 'xbInfo' || curStep == 'xb') && xbxxStatus" -->
      <div v-else>
        <div v-if="curStep == 'xbInfo' || curStep == 'xb'">
          <div class="steps-item-content" v-if="xbxxStatus == 3">
            <!-- 民办学校选报详情 -->
            <mbxb-info ref="mbxbDetail"></mbxb-info>
          </div>
          <div class="steps-item-content" v-else>
            <!-- 民办学校选报填报 -->
            <div v-if="!lqxxStatus">
              <mbxb-form ref="mbxbForm" @reload="getStatus"></mbxb-form>
            </div>
          </div>
        </div>
      </div>

      <!-- 入学结果 -->
      <div class="steps-item-content" v-if="curStep == 'lqResult'">
        <div class="steps-item-content" v-if="lqResultFlag">
          <div v-if="lqxxStatus == 4">
            <div class="result">
              <img
                class="img"
                :src="require('@/assets/home/right.png')"
                alt=""
              />
              <div class="title">入学结果</div>
              <div class="desc">恭喜您！您已被{{ lqxxName }}录取。</div>
              <div class="btns">
                <a-button
                  type="primary"
                  shape="round"
                  size="large"
                  block
                  class="btn2"
                  @click="lookTzs"
                >
                  查看录取通知书
                </a-button>
                <div>
                  <a-button
                    type="primary"
                    shape="round"
                    size="large"
                    block
                    class="btn2 btn-o"
                    @click="toFbResult"
                  >
                    查看分班结果
                  </a-button>
                  <div
                    style="color: #ff5353; font-size: 14px; margin-top: 6px"
                    v-if="!fbResultFlag"
                  >
                    （暂无分班结果）
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-else-if="lqxxStatus == 9">
            <div class="result" style="padding-top:108px;">
              <img class="img" :src="require('@/assets/home/error.png')" alt="" />
              <div class="desc">非常抱歉，您未被录取。</div>
            </div>
          </div> -->
          <div v-else>
            <div class="result" style="padding-top: 78px">
              <img
                class="img"
                :src="require('@/assets/home/noCheck.png')"
                alt=""
              />
              <div class="desc">暂无入学结果，请耐心等待~</div>
            </div>
          </div>
        </div>
        <div class="steps-item-content" v-else>
          <div class="result">
            <img
              class="img"
              :src="require('@/assets/home/noCheck.png')"
              alt=""
              style="width: 107px"
            />
            <div class="desc">暂无结果，请耐心等待~</div>
          </div>
        </div>
      </div>

      <!-- 学校选报 -->
      <div v-if="curStep === 'xxxbInfo'">
        <SelectionInfo @curStep="curSteps" />
      </div>

    </div>

    <!-- 通知书 -->
    <div class="ant-modal-mask" v-if="showTzs"></div>
    <div v-if="showTzs" class="tzsWrap">
      <div class="top">
        <!-- <img class="img" :src="require('@/assets/home/tzsT.png')" alt="" /> -->
      </div>
      <div class="con">
        <div class="text name">
          <span class="value">{{ lqInfo.userName }}</span> 同学：
        </div>
        <div class="text textIndent">
          祝贺你成为
          <span class="value">{{ lqInfo.lqxxName }}</span>
          这个大家庭的一员，你将在学校里和伙伴们共同学习、生活，度过你美好的校园时光。<br />
        </div>
        <!-- <div class="text textIndent">
          希望你在老师和家长的教育下，茁壮成长！
        </div> -->
        <div class="sign">
          <div class="val">{{ lqInfo.lqxxName }}</div>
          <!-- <div class="val">吕梁市离石区教育科技局</div> -->
          <div class="val">{{ year }}年09月</div>
        </div>
        <div class="tip" v-if="lqInfo.tsxx">
          <div class="title">入学注意事项：</div>
          <div v-html="lqInfo.tsxx"></div>
        </div>
      </div>
      <div class="bottom">
        <!-- <img class="img" :src="require('@/assets/home/tzsB.png')" alt="" /> -->
      </div>
      <div class="close" @click="showTzs = false">
        <a-icon type="close-circle" />
      </div>
    </div>

    <!-- 分班结果 -->
    <a-modal
      v-model="showFb"
      title="分班结果"
      :footer="null"
      :width="480"
      :closable="false"
      :centered="true"
      :maskClosable="false"
    >
      <div class="resultWrap">
        <!-- 有结果 -->
        <div v-if="className && fbResultFlag">
          <img class="img" :src="require('@/assets/home/right.png')" alt="" />
          <div class="title">分班结果</div>
          <div class="desc">
            恭喜！您的班级已分配 <br />
            班级为：{{ className }}
          </div>
        </div>
        <!-- 无结果 -->
        <div v-else>
          <img
            class="img"
            :src="require('@/assets/home/noCheck.png')"
            alt=""
            style="width: 107px"
          />
          <div class="desc">暂无分班结果，请耐心等待~</div>
        </div>

        <a-button
          type="primary"
          shape="round"
          size="large"
          block
          class="result-btn btn"
          @click="closeFb"
        >
          返回
        </a-button>
      </div>
    </a-modal>

    <a-modal
      v-model="selectionVisible"
      title="学校选报"
      @ok="handleOk"
      okText="报名"
      :width="750"
      :bodyStyle="bodyStyle"
    >
      <p style="text-align: center">
        吕梁一小已开放选报，请核对您是否满足以下报名条件
      </p>
      <p style="color: red; text-align: center">
        XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX
      </p>
    </a-modal>

    <!-- 二次选报 -->
    <a-modal
      title="温馨提示"
      :visible="reminderVisible"
      :maskClosable="false"
      :keyboard="false"
      :footer="null"
      @cancel="reminderCancel"
    >
      <div class="reminder">
        <div class="reminder-container">
          <!-- <p>“均衡入学”试点意向公办学校选报已开通</p> -->
          <p>“均衡入学”试点公办学校意向选报已开通</p>
          <p>是否进行选报？</p>
        </div>

        <div class="reminder-footer">
          <a-button @click="reminderOk" type="primary" shape="round" class="reminder-footer-btn btn-ok">进入选报</a-button>
          <a-button @click="reminderCancel" shape="round" class="reminder-footer-btn btn-cancel">取消</a-button>
        </div>
      </div>
    </a-modal>

    <Footer />
  </div>
</template>

<script>
import moment from "moment";
import {
  getStatus,
  getLqInfo,
  getClassInfo,
  getintentioncheck,
} from "@/api/api";
import { getDetail } from "@/api/form";
import { getFlag } from "@/api/common";
import HomeHeader from "@/components/HomeHeader";
import Footer from "@/components/footer";
import BaseInfo from "@/views/form/baseInfo";
import XxdjInfo from "@/views/form/xxdjInfo";
import XxdjForm from "@/views/form/xxdjForm";
import MbxbInfo from "@/views/form/mbxbInfo";
import MbxbForm from "@/views/form/mbxbForm";
import SelectionInfo from "@/views/form/selectionInfo";
import Table from "@/views/form/table";

export default {
  name: "Home",
  components: {
    HomeHeader,
    Footer,
    BaseInfo,
    XxdjInfo,
    MbxbInfo,
    Table,
    MbxbForm,
    XxdjForm,
    SelectionInfo,
  },
  data() {
    return {
      selectionVisible: false, //学校选报弹框
      showTzs: false, // 通知书
      showFb: false, // 分班
      hyResult: false, // 核验步骤默认有
      lqResultFlag: false, // 入学结果开关
      fbResultFlag: "", // 分班结果开关
      regStatus: "", // 注册状态（1  待审核  2 注册成功 3退回）
      xxdjStatus: "", //  信息登记状态（1 保存  2提交）
      dydjbStatus: "", // 打印登记表状态（1 打印 2 未打印）
      xbxxStatus: "", // 选报学校状态（1 默认 2 保存 3 提交） 为3 时，lqxxStatus == 1
      lqxxStatus: "", // 录取状态 （1待审核 2审核通过待接收 3已接收待教育局确认  4确认录取  9退回 11已分配待发布）
      lqxxName: "", // 录取学校
      className: "", // 分配班级
      curStep: "",
      lqInfo: {}, // 通知书信息
      year: new Date().getFullYear(),
      bodyStyle: { height: "150px" },
      yxSchoolType: "", // 学校类型 1公立 2民办

      reminderVisible: false, // 二次选报温馨提示

    };
  },
  filters: {
    dateFilter(val) {
      return moment(+val).format("YYYY-MM-DD");
    },
  },
  methods: {
    handleOk() {
      this.selectionVisible = false;
      this.curStep = "xxxbInfo";
    },
    curSteps() {
      this.init();
    },
    // 阶段切换
    toChange(val) {
      this.curStep = val;
      // if (val == 'baseInfo') {
      //   this.$refs.baseDetail.getDic();
      // } else if (val == 'xxdjInfo') {
      //   this.$refs.xxdjDetail.getDetailInfo();
      // } else if (val == 'xxdj') {
      //   this.$refs.xxdjForm.getDic();
      // } else if (val == 'xb') {
      //   this.$refs.mbxbForm.getNotice();
      // } else if (val == 'xbInfo') {
      //   this.$refs.mbxbDetail.getDetailInfo();
      // } else if (val == 'download') {
      //   this.$refs.tableInfo.getTable();
      // }
    },
    // 基本信息查看
    toBaseInfo() {},
    // 信息登记
    toNext() {
      getFlag({
        paramKey: "xxdjFlag",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          this.curStep = "xxdj";
          this.hyResult = true;
        } else {
          this.$message.info("填报暂未开启");
        }
      });
    },
    // 打印登记表

    // 民办学校选报

    // 通知书查看
    lookTzs() {
      this.showTzs = true;
      this.getLqInfo();
    },
    // 关闭通知书弹框
    closeTzs() {
      this.showTzs = false;
    },
    // 分班结果查看
    toFbResult() {
      this.getClass();
      this.showFb = true;
    },
    // 关闭分班弹框
    closeFb() {
      this.showFb = false;
    },
    // 获取学生状态
    getStatus() {
      getStatus().then((res) => {
        this.regStatus = res.data.regStatus;
        this.xxdjStatus = res.data.xxdjStatus;
        this.dydjbStatus = res.data.dydjbStatus;
        this.xbxxStatus = res.data.xbxxStatus;
        this.lqxxStatus = res.data.lqxxStatus;
        this.lqxxName = res.data.lqxxName;
        this.yxSchoolType = res.data.yxSchoolType || ''
        
        let isShowTwice = sessionStorage.getItem("twiceStatus") || "true"
        if (res.data.twiceStatus == 2 && isShowTwice=="true") {
          this.showReminderVisible()
          sessionStorage.setItem("twiceStatus","false")
        }
        this.twiceStatus = res.data.twiceStatus || 3

        this.init();
      });
    },
    // 设置二次选报modal
    showReminderVisible(){
      this.reminderVisible = true;
    },
    // 获取录取信息
    getLqInfo() {
      getLqInfo().then((res) => {
        this.lqInfo = res.data;
      });
    },
    // 获取分班信息
    getClass() {
      getClassInfo().then((res) => {
        this.className = res.data;
      });
    },
    // 当前状态显示哪个步骤模块
    init() {
      if (this.regStatus == 2) {
        // 注册核验通过
        if (this.xxdjStatus == 1) {
          // 信息登记保存
          this.curStep = "xxdj";
          this.hyResult = true;
          // 添加+++
        } else if (this.xxdjStatus == 2) {
          // 信息登记提交
          this.hyResult = true;
          if (this.dydjbStatus == 2) {
            // 未打印
            this.curStep = "download";
          }
        } else {
          this.curStep = "regResult";
          this.hyResult = false;
        }
      }

      // if (this.xxdjStatus == 2) {
      // this.curStep = 'download'
      // this.$refs.tableInfo.getTable();
      // }
      //  this.xbxxStatus == 1 && this.lqxxStatus 未选报已分配学校--跳过该步骤

      if (this.dydjbStatus == 1) {
        // 打印登记表完成
        if (this.xbxxStatus) {
          // 有民办学校选报
          if (this.xbxxStatus == 1) {
            // 民办学校选报--未提交
            if (this.lqxxStatus) {
              this.curStep = "lqResult";
            } else {
              this.curStep = "xb";
            }
          } else if (this.xbxxStatus == 2) {
            // 民办学校选报--保存
            this.curStep = "xb";
          } else if (this.xbxxStatus == 3) {
            // 民办学校选报--提交
            this.curStep = "lqResult";
          }
        } else {
          // 无民办学校选报--入学结果
          this.curStep = "lqResult";
        }
      }

      if (this.twiceStatus == 1 || this.twiceStatus == 2) {
        this.curStep = 'xxdjInfo'
      }
      // 如果路由参数中有 isShowXxdjInfo 说明是二次选报 展示信息登记页
      console.log(this.$route);
      if (this.$route.query.isShowXxdjInfo) {
        this.curStep = 'xxdjInfo'
      }
    },
    // 录取开关
    lqFlag() {
      getFlag({
        paramKey: "lqResult",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          this.lqResultFlag = true;
        } else {
          this.lqResultFlag = false;
        }
      });
    },
    // 分班开关
    fbFlag() {
      getFlag({
        paramKey: "fbResult",
        schoolCode: localStorage.getItem("schoolCode"),
      }).then((res) => {
        if (res.data.paramValue == 1) {
          this.fbResultFlag = true;
        } else {
          this.fbResultFlag = false;
        }
      });
    },
    // 是否展示弹框
    // getDetail() {
    //   getDetail().then((res) => {
    //     getintentioncheck({ plan: res.data.planId }).then((res) => {
    //       this.selectionVisible = res.data;
    //     });
    //   });
    // },
    reminderOk(){
      this.$router.push({ path: "/SecondSelectionReport" });
      this.reminderCancel();
    },
    reminderCancel(){
      this.reminderVisible = false;
      this.toChange('xxdjInfo')
    },
  },
  mounted() {
    this.getStatus();
    this.lqFlag();
    this.fbFlag();
    // this.getDetail();

    // this.init() // 测试状态用
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-modal-content {
  top: 100px;
}
.wrap {
  width: 1000px;
  min-height: 448px;
  background-color: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: -50px auto 30px;
  position: relative;
  z-index: 2;
  padding: 32px 77px;
}
.steps {
  width: 100%;
  height: 36px;
  position: relative;
  .steps-item {
    position: absolute;
    top: 0;
    width: 190px;
    height: 36px;
    background: url(~@/assets/home/middleGray.png) no-repeat;
    cursor: pointer;
    .steps-item-title {
      color: #666;
      font-size: 14px;
      line-height: 36px;
      text-align: center;
    }
    &:nth-child(1) {
      left: 0px;
    }
    &:nth-child(2) {
      left: calc(170px - 16px);
    }
    &:nth-child(3) {
      left: calc(170px + 190px - 32px);
    }
    &:nth-child(4) {
      left: calc(170px + 190px + 190px - 48px);
    }
    &:nth-child(5) {
      left: calc(170px + 190px + 190px + 190px - 64px);
    }
  }
  .steps-first {
    width: 170px;
    height: 36px;
    background: url(~@/assets/home/start.png) no-repeat;
  }
  .steps-last {
    width: 170px;
    height: 36px;
    background: url(~@/assets/home/endGray.png) no-repeat;
  }
  .steps-item-active,
  .steps-item-finish {
    .steps-item-title {
      color: #fff;
    }
  }
  .steps-item-active {
    background: url(~@/assets/home/middleCur.png) no-repeat !important;
  }
  .steps-item-finish {
    background: url(~@/assets/home/middle.png) no-repeat;
  }
  .steps-first.steps-item-active {
    background: url(~@/assets/home/startCur.png) no-repeat !important;
  }
  .steps-first.steps-item-finish {
    background: url(~@/assets/home/start.png) no-repeat;
  }
  .steps-last.steps-item-active {
    background: url(~@/assets/home/endCur.png) no-repeat !important;
  }
  .steps-last.steps-item-finish {
    background: url(~@/assets/home/end.png) no-repeat;
  }
}
// 入学结果
.result {
  width: 540px;
  margin: 0 auto;
  text-align: center;
  padding-top: 50px;
  .img {
    // width: 150px;
    // height: 164px;
    margin-bottom: 24px;
  }
  .title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 8px;
  }
  .desc {
    font-size: 14px;
    color: #999;
    margin-bottom: 4px;
  }
  .red {
    color: #ff5757;
  }
  .btn {
    margin-top: 44px;
    width: 280px;
    height: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
  .btns {
    display: flex;
    justify-content: space-around;
  }
  .btn2 {
    width: 240px;
    height: 44px;
    margin-top: 44px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
  .btn-o {
    border-color: #35ab61;
    background: #fff;
    color: #35ab61;
    box-shadow: none;
  }
}
// 分班结果
.resultWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  .img {
    margin-bottom: 12px;
  }
  .title {
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .desc {
    font-size: 14px;
    color: #999;
  }
  .result-btn {
    width: 280px;
    margin-top: 36px;
    margin-bottom: 16px;
    box-shadow: 0px 7px 12px 0px rgba(53, 171, 97, 0.33);
  }
}
// 通知书
.tzsWrap {
  // background: url(~@/assets/home/tzsBg.png) no-repeat;
  // height: 654px;
  width: 866px;
  // background-size: cover;
  position: absolute;
  z-index: 1000;
  top: 60px;
  // top: 50%;
  left: 50%;
  margin-left: -433px;
  // transform: translate(-50%,-50%);
  .top {
    background: url(~@/assets/home/tzsT.png) no-repeat;
    height: 155px;
    width: 866px;
  }
  .bottom {
    background: url(~@/assets/home/tzsB.png) no-repeat;
    height: 105px;
    width: 866px;
  }
  .close {
    font-size: 28px;
    color: #fff;
    position: absolute;
    // bottom: -40px;
    // left: 420px;
    top: -16px;
    right: -16px;
    cursor: pointer;
  }
  .con {
    padding: 16px 64px 42px;
    font-size: 18px;
    color: #bb0416;
    background: url(~@/assets/home/tzsC.png) repeat;
    min-height: 80px;
    width: 866px;
    .text {
      line-height: 28px;
      margin-top: 4px;
    }
    .value {
      font-weight: 600;
      padding: 0 4px;
    }
    .textIndent {
      text-indent: 2em;
    }
    .name {
      margin-bottom: 8px;
      .value {
        padding-left: 0;
      }
    }
    .sign {
      font-size: 16px;
      width: 200px;
      text-align: center;
      font-weight: 600;
      float: right;
      .val {
        margin-top: 4px;
      }
    }
  }
  .tip {
    padding: 48px 0px 0px;
    font-size: 14px;
    color: #333;
    .title {
      font-weight: 600;
      margin-bottom: 6px;
    }
  }
}
.application {
  padding-left: 12px;
  font-size: 16px;
}

// 温馨提示
.reminder{
  .reminder-container{
    text-align: center;
    margin: 30px 0 50px;
  }

  .reminder-footer{
    display: flex;
    justify-content: space-around;
    padding: 0 40px;

    .reminder-footer-btn{
      padding: 0 40px;
    }
  }
}
</style>
